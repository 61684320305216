<template>
  <v-card>
    <v-dialog v-model="service_dialog" max-width="600">
      <v-card>
        <v-toolbar color="grey darken-3" dark elevation="1">
          <v-toolbar-title>
            Створення нової послуги
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-6">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="new_service_name" hide-details filled
                  color="grey"
                  label="Назва послуги"
                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                  :class="new_service_name ? '' : 'req-star'"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              depressed
              text
              color="secondary darken-1"
              @click.stop="createOneTimeService"
              class="button-accept"
              v-if="savable"
              :loading="getModalLoading"
          >
            <v-icon left>mdi-content-save</v-icon>
            Зберегти
          </v-btn>
          <v-btn depressed text tile color="grey" @click.stop="service_dialog = false">
            <v-icon left>mdi-close</v-icon>
            Відмінити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog" v-if="savable">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-6">
      <v-form v-model="formValid" ref="form">
        <v-row class="dense-wrapper">
          <v-col cols="12" class="pt-0">
            <v-switch
                class="mt-1"
                v-model="all_flats"
                hide-details
                label="Усі абоненти організації"
                color="secondary"/>
          </v-col>
          <v-col cols="12">
            <AddressElementSelect v-model="city_id"
                                  label="Населений пункт" filled
                                  select_type="city"
                                  :disabled="all_flats"
            />
          </v-col>
          <v-col cols="12">
            <AddressElementSelect :parent_id="city_id" v-model="streets"
                                  label="Вулиця(-ки)" multiple filled
                                  select_type="street" use_parent_id
                                  :disabled="!city_id || all_flats"
            />
          </v-col>
          <v-col cols="12">
            <AddressElementSelect :parent_id="streets" v-model="buildings"
                                  label="Будинок(-ки)" multiple filled
                                  select_type="building" use_parent_id
                                  :disabled="!streets.length || all_flats"
            />
          </v-col>
          <v-col cols="12">
            <v-select :items="calculateMethodsSelect" hide-details color="grey" filled
                      v-model="calculate_method"
                      label="Вид розрахунку"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="calculate_method ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-select :items="services" filled label="Послуга"
                      hide-details
                      v-model="service_id"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id ? '' : 'req-star'"
                      color="grey"
                      class="mr-2"
            />
            <v-btn height="100%" depressed color="success" @click.stop="openServiceDialog">
              <v-icon>mdi-plus</v-icon>
              Створити послугу
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="calculate_method ? calculate_method === 'by_square' : false">
            <AC_FlatIndicatorType :value="indicator_id" @autocompleteChange="flatIndicatorTypeChange"
                                  :class="indicator_id ? '' : 'req-star'" color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="tariff" hide-details v-decimal filled color="grey"
                label="Тариф/Сума"
                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                :class="tariff ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-switch
                class="mt-3"
                v-model="main_contract"
                hide-details
                label="Основний договір абонента"
                color="secondary"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createFlatIndicator" :loading="getModalLoading"
             class="button-accept" v-if="savable">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {SELECT_SERVICES_ONE_TIME} from "@/store/actions/services";
import oneTimeChargeAPI from "@/utils/axios/one_time_charge"

import ModalComponentMixin from "@/mixins/modal_component";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const modalDeleteId = 'one_time_charge_legacy_modal_delete'

export default {
  name: "HWP_One_Time_Charge_Legacy",
  mixins: [ModalComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    AC_FlatIndicatorType: () => import("@/components/autocomplite/AC_FlatIndicatorType")
  },
  data() {
    return {
      new_service_name: '',
      service_dialog: false,
      navigation: false,
      all_flats: this.item.all_flats || false,
      city_id: this.item.city_id || null,
      streets: this.item.streets || [],
      buildings: this.item.buildings || [],
      calculate_method: this.item.calculate_method || null,
      indicator_id: this.item.indicator_id || null,
      service_id: this.item.service_id || null,
      comment: this.item.comment || '',
      tariff: this.item.tariff || 0,
      month: this.item.month || null,
      main_contract: this.item.main_contract || false,
      calculateMethodsSelect: [
        { value: 'by_square', text: 'по площі' },
        { value: 'by_fixed', text: 'фіксована сума' },
      ]
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectOneTime',
      current_month: 'getCurrentMonthLegacy',
    }),
    savable() {
      if (!this.month) {
        return false
      }
      return this.current_month === this.month;

    }
  },
  methods: {
    ...mapActions({
      fetchServices: SELECT_SERVICES_ONE_TIME,
    }),
    documentReady() {
      if (!this.all_flats) {
        if (!this.streets.length && !this.city_id && !this.buildings.length) {
          return false
        }
      }
      return true
    },
    createOneTimeService() {
      if (!this.new_service_name) {
        this.$store.commit(ALERT_SHOW, { text: 'Вкажіть назву послуги.', color: 'error' })
        return null
      }

      oneTimeChargeAPI.create_service(this.new_service_name)
        .then(response => response.data)
        .then(data => {
          this.fetchServices()
            .then(() => {
              const service = this.services.find(item => item.value === data.value)
              if (service) {
                this.service_id = service.value
              }
              this.service_dialog = false
            })
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
    },
    openServiceDialog() {
      this.service_dialog = true
      this.new_service_name = ''
    },
    flatIndicatorTypeChange(payload) {
      this.indicator_id = payload?.value || null
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.new_service_name = ''
      this.service_dialog = false
      this.all_flats = this.item.all_flats || false
      this.city_id = this.item.city_id || null
      this.streets = this.item.streets || []
      this.buildings = this.item.buildings || []
      this.calculate_method = this.item.calculate_method || null
      this.indicator_id = this.item.indicator_id || null
      this.service_id = this.item.service_id || null
      this.comment = this.item.comment || ''
      this.tariff = this.item.tariff || 0
      this.month = this.item.month || null
      this.main_contract = this.item.main_contract || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення разового нарахування: ${this.tariff}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    createFlatIndicator() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false

        const text = this.calculate_method === 'by_square'
            ? 'Вид розрахунку, послуга та сума/тариф - мають бути заповнені'
            : 'Вид розрахунку, послуга, вид показника та сума/тариф - мають бути заповнені'

        this.$store.commit(ALERT_SHOW, {
          text: text,
          color: 'error lighten-1'
        })
        return
      }

      if (!this.documentReady()) {
        this.$store.commit(ALERT_SHOW, {
          text: 'Оберіть "усі квартири" або оберіть необхідні Вам параметри відбору (вулиці, будинки)',
          color: 'error lighten-1'
        })
        return;
      }

      const payload = {
        all_flats: this.all_flats,
        city_id: this.city_id ? this.city_id : null,
        streets: this.streets ? this.streets : [],
        buildings: this.buildings ? this.buildings : [],
        calculate_method: this.calculate_method,
        indicator_id: this.indicator_id ? this.indicator_id : null,
        service_id: this.service_id,
        comment: this.comment ? this.comment : null,
        tariff: this.tariff,
        month: this.current_month,
        main_contract: this.main_contract || false,
        building_type: null,
        flat_type: 'all'
      }

      this.$store.commit(START_MODAL_LOADING)

      if (this.isNew) {
        oneTimeChargeAPI.create_legacy(payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.$emit('crud', Object.assign({}, data, {method: 'create'}))
              this.closeModal()
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
          .finally(() => {
            this.$store.commit(STOP_MODAL_LOADING)
          })
      } else {
        payload['id'] = this.itemId
        oneTimeChargeAPI.update_legacy(payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.$emit('crud', Object.assign({}, data, {method: 'update'}))
              this.closeModal()
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
          .finally(() => {
            this.$store.commit(STOP_MODAL_LOADING)
          })
      }

    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.new_service_name = ''
              this.service_dialog = false
              this.all_flats = payload.all_flats || false
              this.city_id = payload.city_id || null
              this.streets = payload.streets || []
              this.buildings = payload.buildings || []
              this.calculate_method = payload.calculate_method || null
              this.indicator_id = payload.indicator_id || null
              this.service_id = payload.service_id || null
              this.comment = payload.comment || ''
              this.tariff = payload.tariff || 0
              this.month = payload.month || null
              this.main_contract = payload.main_contract || false
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  oneTimeChargeAPI.remove_legacy(this.itemId)
                  .then(response => response.data)
                  .then(data => {
                    if (data) {
                      this.$emit('crud', Object.assign({id: this.itemId}, {method: 'delete'}))
                      this.closeModal()
                    }
                  })
                  .catch(err => {
                    const error = err.response.data.detail;
                    this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                  })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  created() {
    this.fetchServices()
  }
}
</script>
